import React from 'react'
import { Container } from 'react-bootstrap'

const DeliveryInfo = () => {
    return (
        <>
           <section className="py-4" style={{ backgroundColor: "#fff" }}>
            <Container className='con'>
            <div className="position-relative">
            <h2 className="title">Delivery information</h2>
          </div>
          <div className='delivery-info'>
            <p>Welcome to Sara Stock Design's Shipping Policy page. We strive to provide our customers with a seamless shopping experience and ensure that their orders are processed and delivered efficiently. Here, you'll find detailed information about our shipping methods, processing times, and delivery expectations for digital and physical products. Whether you're downloading a design instantly or awaiting a physical item, we're committed to transparency and timely service. Please review our policy to understand how we handle your orders and ensure your satisfaction.</p>
            <h3>Instant Downloads</h3>
            <ul>
                <li>Digital products are available for download immediately after purchase.</li>
                <li>Customers can access purchased products from the download section of their account.</li>
                <li>If you encounter any issues with downloads, contact us for assistance.</li>
            </ul>
            <h3>Physical Products</h3>
            <ul>
                <li>Orders of shippable items are dispatched within 10-12 business days.</li>
                <li>We provide worldwide shipping.</li>
                <li>A tracking number will be provided to monitor the shipment's progress.</li>
            </ul>
            <h3>Contact Information</h3>
            <p>For any issues or queries, please contact us at rgembroiderydesigns@gmail.com.</p>
          </div>
            </Container>
            </section> 
        </>
    )
}

export default DeliveryInfo
