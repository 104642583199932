import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { allProducts } from "../Providers/Apicalls";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { API_URL } from "../Config";
import FreeBees from "./FreeBees";
import { Link } from "react-router-dom";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
}

const FreeBanners = () => {
  const [data, setData] = useState<Product[]>([]);
  const [freeData, setFreeData] = useState<Product | null>(null);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 5,
    speed: 500,
    dots: false,
    arrows: true,
    autoplaySpeed: 2000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          centerPadding: "0px",
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          centerPadding: "0px",
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          centerPadding: "0px",
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          centerPadding: "0px",
        }
      }
    ]
  };

  const getAllProduct = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("getAllProduct"));
      const freeProduct = firstResponse.products.filter(
        (product: Product) => product.isFreebie === true
      );
      setData(freeProduct);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  const handleImageClick = (item: Product) => {
    setFreeData(item);
  };


  return (
    <>
    <section className="py-4 ">
      <Container className="con">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12"><div>
            <h2 className="title">Freebees</h2>
            <h5>Welcome to our Freebies page!</h5>
            <p>As a token of our appreciation for your support, we are excited to offer a selection of complimentary embroidery and print designs. Here, you'll find a variety of exclusive, high-quality patterns and designs that are perfect for personal projects or to simply inspire your creativity. Our free designs are crafted with the same attention to detail and artistic flair as our premium collections, ensuring you have access to beautiful and versatile options. Whether you're a hobbyist looking to experiment with new styles or a professional seeking fresh inspiration, our Freebies page is here to provide you with delightful designs at no cost. Enjoy exploring and creating with our free offerings, and don't forget to check back regularly for new additions!</p>
            </div></div>
          <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center"><div>
            <img className="img-fluid" src="/assets/Rectangle 244 (2).png"/>
            </div></div>
        </div>
      </Container>
    </section>
      <section className="py-3" style={{ backgroundColor: "#fff" }}>
        <Container>
          <div className="slider-container">
            {data.length>2?
            <Slider {...settings}>
              {data.map((item, index) => (
                <div key={index} className="p-2">
                  <img
                    src={`${API_URL}/public/images/${item.image}`}
                    alt={`product ${index + 1}`}
                    style={{ width: "100%", height: "auto", cursor: "pointer" }}
                    onClick={() => handleImageClick(item)}
                  />
                </div>
              ))}
            </Slider>:<h2 className="text-center">Come back later buddy!</h2>}
            <div className='text-center pt-5 pb-2'><Link to="/all-design" style={{textDecoration:"none",color:"#fff"}}><Button className='button-medium' variant="contained">Explore More</Button></Link></div>

          </div>
          {freeData && <FreeBees data={freeData} />}
        </Container>
      </section>
    </>
  );
};

export default FreeBanners;
