import { Button, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { IoIosShareAlt } from "react-icons/io";
import { useParams } from "react-router-dom";
import { ApiEndPoints } from "../Providers/api_endpoints";
import {
  deleteitem,
  forgetPass,
  getMe,
  getProductreview,
  getSlugProduct,
} from "../Providers/Apicalls";
import { API_URL } from "../Config";
import { message } from "antd";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
}

interface Review {
  _id: string;
  user: {
    _id: string;
    name: string;
  };
  rating: number;
  review: string;
  date: string;
}

type UserData = {
  name: string;
  phone: number | null;
  email: string;
  role: string;
  orders: [];
  cart: Product[];
  wishlist: Product[];
  wallet: number | null;
  _id: string;
};

const EmbroideryDesign = () => {
  const { slug } = useParams<{ slug: string }>();
  const [productData, setProductData] = useState<Product | null>(null);
  const [reviewData, setReviewData] = useState<Review[]>([]);
  const [visibleReviews, setVisibleReviews] = useState<Review[]>([]);
  const [reviewsShown, setReviewsShown] = useState<number>(2);
  const [userData, setUserData] = useState<UserData>({
    name: "",
    phone: null,
    email: "",
    role: "",
    orders: [],
    cart: [],
    wishlist: [],
    wallet: null,
    _id: "",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px", // Set the font size
        fontWeight: "bold", // Set the font weight
      },
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        fontSize: "16px", // Set the font size
        fontWeight: "bold", // Set the font weight
      },
    });
  };

  const handleProduct = async () => {
    try {
      const fetchProduct = await getSlugProduct(
        ApiEndPoints("getbyslug"),
        slug || ""
      );
      await handleReviewProduct(fetchProduct.product._id);
      setProductData(fetchProduct.product);
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const handleReviewProduct = async (id: string) => {
    try {
      const fetchReviews = await getProductreview(
        ApiEndPoints("getreviewbyID"),
        id
      );
      setReviewData(fetchReviews.reviews);
      setVisibleReviews(fetchReviews.reviews.slice(0, 2)); // Show initial 2 reviews
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  const handleSeeMore = () => {
    const nextReviews = reviewsShown + 10;
    setVisibleReviews(reviewData.slice(0, nextReviews));
    setReviewsShown(nextReviews);
  };

  const getUser = async () => {
    try {
      const getMeuser = await getMe(ApiEndPoints("getMe"));
      setUserData(getMeuser.user);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const isProductInWishlist = (productId: string) => {
    return userData.wishlist.some((item) => item._id === productId);
  };

  const isProductInCart = (productId: string) => {
    return userData.cart.some((item) => item._id === productId);
  };

  const handleWishlist = async (
    product: Product,
    action: "wishlist" | "cart"
  ) => {
    try {
      // Prepare payload to be sent to the server
      const payload = { product: product._id };

      if (action === "wishlist") {
        if (isProductInWishlist(product._id!)) {
          // Remove from wishlist
          await deleteitem(ApiEndPoints("removeFromWishlist"), payload);
          // Update local state to reflect removal
          setUserData((prevState) => ({
            ...prevState,
            wishlist: prevState.wishlist.filter(
              (item) => item._id !== product._id
            ),
          }));
        } else {
          // Add to wishlist
          await forgetPass(ApiEndPoints("addtoWishlist"), payload);
          // Update local state to reflect addition
          setUserData((prevState) => ({
            ...prevState,
            wishlist: [...prevState.wishlist, product],
          }));
        }
      } else if (action === "cart") {
        if (isProductInCart(product._id!)) {
          // Remove from cart
          await deleteitem(ApiEndPoints("removeFromCart"), payload);
          // Update local state to reflect removal
          setUserData((prevState) => ({
            ...prevState,
            cart: prevState.cart.filter((item) => item._id !== product._id),
          }));
        } else {
          // Add to cart
          await forgetPass(ApiEndPoints("addtoCart"), payload);
          // Update local state to reflect addition
          setUserData((prevState) => ({
            ...prevState,
            cart: [...prevState.cart, product],
          }));
        }
      }

      successmessage(
        `Product ${
          action === "wishlist" ? "wishlist" : "cart"
        } updated successfully.`
      );
    } catch (error: any) {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  useEffect(() => {
    handleProduct();
  }, [slug]);

  if (!productData) {
    return <div>Loading...</div>;
  }

  const shareData = {
    title: "RG Designs",
    text: "Buy all types of design at one place",
    url: window.location.href,
  };
  const handleShare = async () => {
    try {
      await navigator.share(shareData);
    } catch (err: any) {
      message.error(err.response.message);
    }
  };

  return (
    <>
      {contextHolder}
      <section className="py-4" style={{ backgroundColor: "#fff" }}>
        <Container className="con">
          <div className="row" style={{backgroundColor:"#E2F5FA",border:"1px solid #6FCFE7"}}>
            <div className="col-lg-5 col-md-6 col-sm-12 product-heigth">
              <div>
                <img
                  src={`${API_URL}/public/images/${productData.image}`}
                  alt={productData.name}
                  className="img-fluid embroidery-img"
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 product-heigth">
              <div className="embroidery-content py-3 px-4">
                <div className="d-flex justify-content-start align-items-center">
                  <h3 className="embroidery-title">{productData.name}</h3>
                  <div className="heart-icon">
                    {isProductInWishlist(productData._id!) ? (
                      <FaHeart
                        onClick={() => handleWishlist(productData, "wishlist")}
                        style={{ color: "red" }}
                      />
                    ) : (
                      <FaRegHeart
                        onClick={() => handleWishlist(productData, "wishlist")}
                      />
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center flex-wrap">
                  <Rating
                    name="read-only"
                    value={productData.ratingsAverage || 0}
                    readOnly
                  />
                  <p className="embroidery-rating mb-0">
                    {productData.reviewsCount} ratings *{" "}
                    {productData.reviewsCount} Reviews{" "}
                    <span className="embroidery-totalbuyers">
                      ( {productData.buyersCount} Buyers)
                    </span>
                  </p>
                </div>
                <p className="embroidery-Category py-2">
                  Category : {productData.category || "N/A"}
                </p>
                <div className="d-flex justify-content-between flex-wrap align-items-center mb-5">
                  <div>
                    <h3 className="embroidery-price mb-0">
                      ${productData.price?.toFixed()}
                    </h3>
                  </div>
                  <div>
                    {isProductInCart(productData._id!) ? (
                      <Button
                        onClick={() => handleWishlist(productData, "cart")}
                        variant="contained"
                        className="button-medium"
                      >
                        Added
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleWishlist(productData, "cart")}
                        variant="contained"
                        className="button-medium"
                      >
                        Add To Cart
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      className="share-btn mx-3"
                      onClick={handleShare}
                    >
                      <IoIosShareAlt />
                    </Button>
                  </div>
                </div>
                <div>
                  {productData.category === "embroidery design" ? (
                    <Table className="view-table">
                      <tbody>
                        <tr>
                          <td>Sizes (Inches)</td>
                          <td>{productData.sizes}</td>
                        </tr>
                        <tr>
                          <td>Stitch Count</td>
                          <td>{productData.stitchCount}</td>
                        </tr>
                        <tr>
                          <td>Colors</td>
                          <td>{productData.colors}</td>
                        </tr>
                        <tr>
                          <td>Formats</td>
                          <td>{productData.formats}</td>
                        </tr>
                        <tr>
                          <td>Quantity</td>
                          <td>{productData.quantity}</td>
                        </tr>
                      </tbody>
                    </Table>
                  ) : (
                    <>
                      <h2 className="product-desc">Product Description</h2>
                      <p>{productData.description}</p>
                    </>
                  )}
                </div>
                <div className="reviews">
                  <h3 className="reviews-title">Reviews</h3>
                  {visibleReviews.map((review) => (
                    <div key={review._id}>
                      <h5>
                        {review.user.name}{" "}
                        <span className="review-date">
                          {new Date(review.date).toLocaleDateString()}
                        </span>
                      </h5>
                      <Rating name="read-only" value={review.rating} readOnly />
                      <p className="review-text">{review.review}</p>
                    </div>
                  ))}
                  {reviewData.length > reviewsShown && (
                    <Button onClick={handleSeeMore}>See More</Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default EmbroideryDesign;
