import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Youmaylike from "./Youmaylike";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IoCartSharp } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { allProducts, capturepay, deleteitem, forgetPass, getMe } from "../Providers/Apicalls";
import { API_URL } from "../Config";
import { message } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};


type UserData = {
  name: string;
  phone: number | null;
  email: string;
  role: string;
  orders: any[];
  cart: any[];
  wishlist: string[];
  wallet: number | null;
  _id: string;
};

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  zip:string
}

// Declare the Razorpay type on the window object
declare global {
  interface Window {
    Razorpay: any;
  }
}

type coupon={
  image:string,
  couponCode:string,
  discountPercentage:number,
  expiryDate:string
}

const Shoppingcart = () => {
  const [open, setOpen] = useState(false);
  const [ispayment, setIspayment] = useState(false);
  const [couponInput,setCouponinput]=useState("")
  const [userData, setUserData] = useState<UserData>({
    name: '',
    phone: null,
    email: '',
    role: '',
    orders: [],
    cart: [],
    wishlist: [],
    wallet: null,
    _id: '',
  });

  const [ogData, setOgData] = useState<Product[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [coupon, setCoupon] = useState<coupon|null>(null);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handlepaymentOpen = () => {
    setOpen(false);
    setIspayment(true);
  };
  const handlepaymentClose = () => setIspayment(false);

  const calculateTotalPrice = (products: Product[]): number => {
    return products.reduce((total, product) => {
      if (product.isFreebie) {
        return total; // Add zero for freebies
      }
      return total + (product.price || 0);
    }, 0);
  };

  
  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px", 
        fontWeight: "bold", 
      },
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        fontSize: "16px", // Set the font size
        fontWeight: "bold", // Set the font weight
      },
    });
  };

  const getUser = async () => {
    try {
      const getMeUser = await getMe(ApiEndPoints('getMe'));
      setUserData(getMeUser.user);
      await getAllProduct(getMeUser.user.cart);
      await getAllCoupon()
    } catch (error) {
      console.log(error);
    }
  };
const [subtotal,setSubtotal]=useState(0)
  const getAllProduct = async (cart: string[]) => {
    try {
      const firstResponse = await allProducts(ApiEndPoints('getAllProduct'));
      const cartProducts = firstResponse.products.filter(
        (product: Product) =>
          product._id && cart.includes(product._id) 
      );
      setOgData(cartProducts);
      setSubtotal(calculateTotalPrice(cartProducts));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete=async(id:any)=>{
    try{
      await deleteitem(ApiEndPoints("removeFromCart"), {product:id});
      await getUser()
    }
    catch(error){
      console.error(error)
    }
  }


  const [loading, setLoading] = useState(false);
  const handlePayment = async (method:any) => {
    setLoading(true);
    const paypalScript = document.createElement("script");
    paypalScript.src = `https://www.paypal.com/sdk/js?client-id=AfZ_9fQMq1jEr_iccVNVnELcQw7rw1FDA5U0cKzASxTXQnSMHwoH2fqurRZ9M-nMFMVXIk04YriC-uJq`;
    paypalScript.onload = () => {
      window.paypal.Buttons({
        createOrder: function (data: any, actions: any) {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: totalPrice
                },
              },
            ],
          });
        },
        onApprove: function (data: any, actions: any) {
          return actions.order.capture().then(async function (details: any) {
            successmessage("Transaction completed by " + details.payer.name.given_name);
            await handleCheckout();
            // await sendEmail()
           
          });
        },
        onError: function (err: any) {
          console.error("PayPal Checkout onError", err);
          errormessage("Payment failed, please try again.");
        },
      }).render("#paypal-button-container");
    };
    document.body.appendChild(paypalScript);
    setLoading(false);
  };
  const handleCheckout = async () => {
    try {

            // Prepare product details from the cart
    const products = ogData.map((product) => ({
      name: product.name,
      price: product.isFreebie ? 0 : product.price, // If freebie, set price to 0
      product:product._id
    }));

    // Calculate total amount from the products
    const totalAmount = products.reduce((total, product) => total + (product.price || 0), 0);

    // Prepare the checkout data including user info, products, and coupon code
    const postData = {
      paypalId:"done",
      userId:userData._id,
      email: userData.email,
      customerName: userData.name, 
      products, // Products from the cart
      totalAmount: totalPrice, // Total amount formatted
      zipLinks: ogData.map((product) => `${API_URL}/public/zips/${product.zip}`), 
      isPaid:true,
    };
      const result = await forgetPass(ApiEndPoints("checkout"), postData);
      await getUser();
      for (const item of userData.cart) {
                await handleDelete(item);
              }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };



const getAllCoupon = async () => {
try {
  const firstResponse = await allProducts(ApiEndPoints("getAllcoupon"));
  let length=firstResponse.coupons.length
  setCoupon(firstResponse.coupons[length-1]);
} catch (error) {
  console.error(error);
}
};
const [iscouponApply,setCouponApply]=useState(false)
const applyCoupon=()=>{
 
  if(couponInput.trim()===coupon?.couponCode&&moment(coupon?.expiryDate).format("YYYY-MM-DD")>=moment(new Date()).format("YYYY-MM-DD")){
    let totalamount=totalPrice*coupon.discountPercentage/100
    let finalamount=totalPrice-totalamount
  setTotalPrice(finalamount)
  setCouponApply(true) 
  successmessage("Coupon Applied")   
  }
  else{
    errormessage("Invalid coupon!")
    setCouponApply(false)
  }
}

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (ogData.length > 0) {
      setTotalPrice(calculateTotalPrice(ogData));
    }
  }, [ogData]);

  return (
    <>
      {contextHolder}
      <section className="py-4" style={{ backgroundColor: "#fff" }}>
        <Container className="con">
          <div className="position-relative">
            <h2 className="title">Shopping Cart</h2>
            {/* <img
              src="/assets/headdecor.png"
              className="img-fluid best-sellingDecor"
            /> */}
          </div>
        </Container>
        <div
          className="py-3"
          style={{ backgroundColor: "#fff", borderRadius: "10px" }}
        >
          <Container>
            <div className="row">
              {userData.cart.length ? (
                <>
                  <div className="col-lg-8">
                    <Table className="cart-table mb-0">
                      <thead>
                        <tr>
                          <th></th>
                          <th className="product-name-tr">Product Name</th>
                          <th className="price text-center">Price</th>
                          <th className="text-end">Cancel</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ogData.length > 0
                          ? ogData.map((val, index) => (
                              <tr key={index}>
                                <td>
                                  <img
                                    className="img-fluid cart-img"
                                    src={`${API_URL}/images/${val.image}`}
                                  />
                                </td>
                                <td className="align-middle product-name-tr">
                                  <p className="mb-0">{val.name}</p>
                                  <span style={{fontSize:"16px",fontWeight:300,color:"#484848"}}>{val.category}</span>
                                </td>
                                <td className="align-middle price text-center">
                                  $ {val.isFreebie ? 0.0 : val.price}
                                </td>
                                <td className="align-middle text-end">
                                  <AiOutlineCloseCircle
                                    style={{ cursor: "pointer" }}
                                    className="cart-cancelicon"
                                    onClick={() => handleDelete(val._id)}
                                  />
                                </td>
                              </tr>
                            ))
                          : ""}
                        <tr>
                          <td></td>
                      
                          <td className="text-end pe-4 cart-totalrow align-middle">
                            Total
                          </td>
                          <td className="cart-totalrow align-middle text-center">
                            $ {subtotal.toFixed(2)}
                          </td>
                          <td className="text-end cart-totalrow align-middle">
                            <Button
                              className="cart-btn"
                              variant="contained"
                              onClick={handleOpen}
                            >
                              Check Out
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  {open&&
                  <div className="col-lg-4 col-sm-12">
                    <div>
                      <div style={{ borderRadius: "10px", overflow: "hidden" }}>
                        <div
                          className="d-flex justify-content-between align-items-center px-3 py-1"
                          style={{ backgroundColor: "#E2F5FA" }}
                        >
                          <div>
                            <h3>Summary</h3>
                          </div>
                          <div>
                            <AiOutlineCloseCircle
                              className="close-icon"
                              onClick={handleClose}
                            />
                          </div>
                        </div>
                        <div className="px-4 pb-4 sum-border">
                          <Table className="price-modal">
                            <tbody>
                              <tr>
                                <td>Price Details :</td>
                                <td className="text-end">$</td>
                              </tr>
                              <tr>
                                <td>Subtotal</td>
                                <td className="text-end">
                                  $ {subtotal.toFixed(2)}
                                </td>
                              </tr>
                              {/* <tr>
                          <td>Tax</td>
                          <td className="text-end">5.0</td>
                        </tr> */}
                              <tr>
                                <td>Order Total</td>
                                <td className="text-end">
                                  $ {totalPrice.toFixed(2)}
                                  <br />
                                  {iscouponApply
                                    ? "-" + coupon?.discountPercentage + "%"
                                    : ""}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <h4 className="coupon-head">Enter Discount Code</h4>
                          <div className="position-relative">
                            <input
                              placeholder="Coupon Code"
                              className="coupon-input"
                              onChange={(e) => setCouponinput(e.target.value)}
                            />
                            <Button
                              variant="contained"
                              disabled={iscouponApply}
                              className={`${
                                iscouponApply ? "dis-apply-btn" : "apply-btn"
                              }`}
                              onClick={applyCoupon}
                            >
                              Apply
                            </Button>
                          </div>
                          <div className="text-center pt-4">
                            <Button
                              variant="contained"
                              className="checkout-btn"
                              onClick={handlepaymentOpen}
                            >
                              Checkout
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
                </>
              ) : (
                <div className="text-center py-2">
                  <h2 className="py-2">Oops no items found!</h2>
                  <Link
                    to="/all-design"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <Button className="button-medium" variant="contained">
                      Explore More
                    </Button>
                  </Link>
                </div>
              )}

              
            

              {/* Payment Modal */}
              <Modal
                open={ispayment}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div style={{ borderRadius: "10px", overflow: "hidden" }}>
                    <div
                      className="d-flex justify-content-between align-items-center px-3 py-1"
                      style={{ backgroundColor: "#E2F5FA" }}
                    >
                      <div>
                        <h3>Payment</h3>
                      </div>
                      <div>
                        <AiOutlineCloseCircle
                          className="close-icon"
                          onClick={handlepaymentClose}
                        />
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="d-flex justify-content-between align-items-center purchase-Head">
                        <div>
                          <h5 className="mb-0">
                            <IoCartSharp /> Ordered Items
                          </h5>
                        </div>
                        <div>
                        <h6 className="mb-0">$ {totalPrice.toFixed(2)}</h6>
                      </div>
                        <div>
                          <h6 className="mb-0">{userData.cart.length} Items</h6>
                        </div>
                      </div>
                      <div className="text-center pt-3">
                    <Button  variant="contained" className="checkout-btn mb-0" onClick={()=>handlePayment("card")}>Pay Now</Button>
                    </div>
                    </div>
                    <div className="px-3 pb-5">
                    <div id="paypal-button-container" className="paypal-div"></div>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
          </Container>
        </div>
      </section>
      <Youmaylike />
    </>
  );
};

export default Shoppingcart;
