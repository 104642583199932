import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
         <Helmet>
        <title>About Us | Sara Stock Designs - Crafting Excellence</title>
        <meta
          name="description"
          content="Learn about Sara Stock Designs, your trusted source for premium embroidery patterns. Discover our story, values, and commitment to delivering high-quality, unique designs for all your crafting needs."
        />
      </Helmet>
      <section className="py-4" style={{ backgroundColor: "#fff" }}>
        <Container className="con">
          <div className="position-relative">
            <h2 className="title">About Us</h2>
        
          </div>
          <div className="about">
            <p>
              {" "}
              Greetings! I'm Raje, the creative force behind our unique and
              exquisite embroidered and vector creations. Embroidery isn't just
              what I do; it's who I am. My passion for creating intricate
              designs has been a driving force for over 15 years, allowing me to
              live my dream every day.
            </p>
            <p>
              {" "}
              At our store, we specialize in custom embroidery and vector art,
              offering thousands of machine embroidery and vector designs
              perfect for all crafts, hoops, and embroidery machines. We pride
              ourselves on being a one-stop-shop for embroidery enthusiasts,
              with new patterns released regularly to spark your creativity for
              your next craft exhibition or hobby project.
            </p>
            <p>
              Customer satisfaction is our top priority. If there's ever an
              issue with a design you've purchased, simply let us know, and we
              will resolve it promptly
            </p>
            <p>
              {" "}
              We offer custom embroidery services and can digitize your existing
              logo to suit your needs. Our turnaround time is swift, ensuring
              your requests are fulfilled quickly and efficiently. Choose from
              our wide selection of items to be embroidered, or bring in your
              own. Our motto is, "If we can hoop it, we can embroider it!"
            </p>
            <p>
              We hope you enjoy shopping with us and that our designs inspire
              you to create amazing projects.
            </p>
          </div>
        </Container>
      </section>
    </>
  );
};

export default About;
