import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Card from '../Card/Card'
import { Button } from '@mui/material'
import { allProducts } from '../Providers/Apicalls'
import { ApiEndPoints } from '../Providers/api_endpoints'
import { Link } from 'react-router-dom'

interface Product {
    _id?: string;
    name: string;
    image: string;
    category?: string;
    price?: number;
    isFreebie?: boolean;
    date?: string;
    description?: string;
    sizes?: string | null;
    stitchCount?: number;
    colors?: number;
    formats?: string;
    quantity?: string;
    buyersCount?: number;
    wishlistCount?: number;
    cartCount?: number;
    reviewsCount?: number;
    ratingsAverage?: number;
    isDeleted?: boolean;
  }

const Newarrive = () => {

    const [ogData, setOgData] = useState<Product[]>([]);


    const getAllProduct = async () => {
        try {
          const firstResponse = await allProducts(ApiEndPoints("getAllProduct"));
          const newarriveProduct = firstResponse.products.filter(
            (product: Product) =>
              product.isFreebie === false
          ).slice(-10);
          setOgData(newarriveProduct);
        
        } catch (error) {
          console.error(error);
        }
      };

      useEffect(()=>{
        getAllProduct()
      },[])

    return (
        <>
            <section className='py-4' style={{backgroundColor:"#fff"}}>
                <Container>
                    <div className='position-relative'>
                        <h2 className='title'>New Arrival</h2>
                        {/* <img src='/assets/headdecor.png' className='img-fluid best-sellingDecor'/> */}
                    </div>
                    <Card data={ogData}/>
                    <div className='text-center pt-5 pb-2'><Link to="/all-design"><Button className='button-medium'  variant="contained">Explore More</Button></Link></div>
                </Container>
            </section>
        </>
    )
}

export default Newarrive
