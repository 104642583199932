import { Button, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import Bestselling from "../Home/Bestselling";
import { MdDriveFolderUpload } from "react-icons/md";
import { allProducts, forgetPass, postCustom } from "../Providers/Apicalls";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { Helmet } from "react-helmet";

const { Dragger } = Upload;

const Customeview = () => {
  const [selectedSize, setSelectedSize] = useState<string>("");
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [description, setDescription] = useState<string>("");
  const [reviews, setReviews] = useState<any[]>([]);
  const [averageRating, setAverageRating] = useState<number>(0);
  const [buyersCount, setBuyersCount] = useState<number>(0);
  const [isActive,setIsActive]=useState(false)

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: string) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const errormessage = (content: string) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const getAllCustomreview = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("customReviews"));
      let d=firstResponse.customReviews.filter((val:any)=>val.category==="custom digitizing")
      setReviews(d);
      // Assuming the response has these properties
      setAverageRating(firstResponse.averageRating);
      setBuyersCount(firstResponse.buyersCount);
    } catch (error) {
      console.error(error);
    }
  };

  const handlepostCustom = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData();
    if (selectedSize) {
      formData.append("size", selectedSize);
    }
    if (description) {
      formData.append("description", description);
    }
    if (uploadedFile) {
      formData.append("image", uploadedFile);
      formData.append("category", "custom digitizing");
    }

    try {
      const result = await postCustom(
        ApiEndPoints("postCustomOrder"),
        formData
      );
      successmessage("Quote request sent successfully");
      setSelectedSize("");
      setDescription("");
      setUploadedFile(null);
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  const props: UploadProps = {
    name: "file",
    beforeUpload: (file) => {
      setUploadedFile(file);
      return false; 
    },
    onRemove: () => {
      setUploadedFile(null);
    },
  };

  useEffect(() => {
    getAllCustomreview();
  }, []);

  return (
    <>
      {contextHolder}
      <Helmet>
        <title>
          Custom Digitizing Services | Sara Stock Designs - Personalized
          Embroidery Solutions
        </title>
        <meta
          name="description"
          content="Get personalized embroidery solutions with our Custom Digitizing services at Sara Stock Designs. Turn your ideas into high-quality embroidery patterns tailored to your needs. Contact us today for custom digitizing."
        />
      </Helmet>
      <section className="py-4" style={{ backgroundColor: "#fff" }}>
        <Container className="con">
          <div className="row">
            {/* Product image and details */}
            <div className="col-lg-5 col-md-5 col-sm-12 product-heigth">
              <div style={{height:"100%"}}>
              <div className="pb-3" style={{height:"100%"}}>
                  <h4 className="coustom-subHead">Upload Your Design</h4>
                  <div style={{height:"100%"}}>
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon mb-0">
                        <MdDriveFolderUpload
                          style={{ color: "#444444", fontSize: "1.5rem" }}
                        />
                      </p>
                      <p className="ant-upload-text" style={{color:"#3D3D3D"}}>
                       Drag & Drop Your design Here
                      </p>
                    </Dragger>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12 product-heigth">
              <div className=" py-3 px-4">
                <div className="d-flex justify-content-start align-items-center">
                  <h3 className="embroidery-title">Request Quotation</h3>
                </div>
                <div className="d-flex justify-content-start align-items-center flex-wrap">
                  <Rating name="read-only" value={averageRating} readOnly />
                  <p className="embroidery-rating mb-0">
                  {(averageRating || 0).toFixed()} ratings * {reviews.length} Reviews{" "}
                    <span className="embroidery-totalbuyers">
                      ( {buyersCount} Buyers)
                    </span>
                  </p>
                </div>
                <div>
                  <h4 className="coustom-subHead pt-2">Customize Your Size</h4>
                  <div className="d-flex justify-content-start align-items-center pb-3">
                    <div className="custom-dropdown">
                      <input
                        onChange={(e) => setSelectedSize(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
            
                <div className="pb-3">
                  <h4 className="coustom-subHead">
                  Leave a description of what is needed for your customized Design:
                  </h4>
                  <textarea
                    className="textarea"
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                  <Button
                    variant="contained"
                    className="button-medium"
                    onClick={handlepostCustom}
                  >
                    Send Quote
                  </Button>
                </div>
              
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="py-4">
        <Container>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12"><div>
              <h2 className="title">Custom Digitizing</h2>
              <Button variant="contained" onClick={()=>setIsActive(false)} className={`desc-btn ${!isActive?"btn-active":"btn-inactive"}`}>Description</Button>
              <Button variant="contained" onClick={()=>setIsActive(true)} className={`review-btn ${isActive?"btn-active":"btn-inactive"}`}>Reviews</Button>
              {!isActive?<div className="py-2">
                <p>Sara Designs converts Digitizing the images, clip arts, arts into machine embroidery files available for instant download in multiple formats like DST, HUS, JEF, PES, XXX, VP3, VIP, EXP. For those seeking something unique, we offer a customization service. Simply submit your design and size specifications using our online form, and we'll review your request and send you a quote. Once you approve the quote and make the payment, our team will craft your custom embroidery design and deliver it to you in the specified digital format. Enjoy seamless access to beautiful embroidery designs tailored to your needs.</p>
              </div>:<>
              <div className="py-2">
              {reviews.map((review) => (
                    <div key={review._id} className="review">
                      <h5>
                        {review.user.name}{" "}
                        <span className="review-date">
                          {new Date(review.date).toLocaleDateString()}
                        </span>
                      </h5>
                      <Rating name="read-only" value={review.rating} readOnly />
                      <p className="review-text">{review.review}</p>
                    </div>
                  ))} <p>{reviews.length===0&&"No review found!"}</p></div></>}
              
              
              </div></div>
            <div className="col-lg-6 col-md-6 col-sm-12"><div>
              <img src="/assets/Rectangle 244.png" className="img-fluid"/>
              </div></div>
          </div>
        </Container>
      </section>
      {/* <Bestselling /> */}
    </>
  );
};

export default Customeview;
