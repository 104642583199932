import { Button } from "@mui/material";
import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Sellingbanner = () => {
  return (
    <>
      <section className="py-3">
        <Container>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="p-1">
                <Link to="/vector-tracing">
                <img
                  className="img-fluid"
                  src="/assets/vector.png"
                  style={{ width: "100%" }}
                /></Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div style={{ height: "100%" }} className="p-1">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div className="py-1">
                    <Link to="/product-category/print-designs">
                    <img
                      className="img-fluid"
                      src="/assets/print.png"
                      style={{ width: "100%" }}
                    />
                    </Link>
                  </div>
                  <div className="py-1">
                    <Link to="/product-category/embroidery-designs">
                    <img
                      className="img-fluid"
                      src="/assets/emb.png"
                      style={{ width: "100%" }}
                    />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="p-1">
                <Link to="/custom-digitizing">
                <img
                  className="img-fluid"
                  src="/assets/custom.png"
                  style={{ width: "100%" }}
                />
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="py-3">
        <Container>
          <div className="row px-2" style={{ backgroundColor: "#E2F5FA" }}>
            <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-start align-items-center">
              <div>
                <h2 className="freebies-head">
                  "Freebies Galore
                  <span className="freespan"> – Take Yours Today!"</span>
                </h2>
                <div className="">
                  <Link to="/freebies">
                    <Button className="button-medium" variant="contained">
                      Explore More
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 bies-box">
              <div>
                <img
                  className="img-fluid bies-bannerimg"
                  src="/assets/free.png"
                  style={{ width: "280px" }}
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Sellingbanner;
