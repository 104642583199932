import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled, { css } from "styled-components";
import { Rate } from "antd";
import { allProducts } from "../Providers/Apicalls";
import { ApiEndPoints } from "../Providers/api_endpoints";

const CarouselContainer = styled.div`
  .slick-slide img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.5s ease;
  }

  .slick-center img {
    transform: scale(1); /* Normal size for the centered slide */
  }

  .slick-slide:not(.slick-center) img {
    transform: scale(1.1); /* Scale up non-centered slides */
    border: 5px solid #fff;
    border-radius: 10px;
    overflow: hidden;
  }

  .slick-slide {
    opacity: 1;
  }

  .slick-center {
    opacity: 1;
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
    font-size: 24px;
    color: #000;
  }

  .slick-prev {
    left: 10px;
    display: none;
  }

  .slick-next {
    right: 10px;
    display: none;
  }

  .slick-track {
    left: 2px;
  }
`;

const Testnimonal = () => {

    const [currentSlides, setCurrentSlides] = useState(0);
    const [allreview,setAllreview]=useState<any[]>([])

    const getAllReview = async () => {
      try {
        const firstResponse = await allProducts(ApiEndPoints("getreviewbyID"));
        setAllreview(firstResponse.reviews)
      } catch (error) {
        console.error(error);
      }
    };


    const settingM = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
        slidesToShow: 1,
        speed: 500,
        arrows: false,
        dots: true,
        appendDots: (dots: any) => (
          <div
            style={{
              position: "absolute",
              width: "500px",
              right: "130%",
              top:"230px"
            }}
          >
            <ul style={{ margin: "0px" }}> {dots} </ul>
          </div>
        ),
        autoplay: true,
        autoplaySpeed: 2000,
        beforeChange: (current: any, next: any) => setCurrentSlides(next),
      };


      useEffect(()=>{
        getAllReview()
      },[])
  return (
    <>
      <section className="py-4">
        <Container>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex justify-content-center align-items-center">
                <div className="gallery">
  <span><img src="/assets/Rectangle 240.png"/></span>
  <span><img src="/assets/Rectangle 241.png"/></span>
  <span><img src="/assets/Rectangle 242.png"/></span>
  <span><img src="/assets/Rectangle 243.png"/></span>
</div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="px-3">
                <h2 className="title">About Us</h2>
                <h4>What & How We Do !</h4>
                <p>
                  Sara Embroidery is known for offering a wide range of
                  embroidery designs and digitizing services. Sara Stock Designs
                  provides various embroidery patterns for different themes like
                  holidays, sports, weddings, and word art, allowing users to
                  choose designs that fit specific occasions or interests. These
                  designs are available for purchase and can be used for
                  creating custom embroidered items​ (Sara Stock Designs)​​ ​.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="banner-6 py-4">
        <Container>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div>
                <h2 className="title">Testimonial</h2>
                <h4 className="home-txt-1">What Our Customers Are Saying</h4>
                <p>
                We pride ourselves on delivering high-quality embroidery designs that inspire creativity and enhance your projects. But don’t just take our word for it—hear from our satisfied customers! Below are some of the wonderful things our clients have to say about their experiences with our products and services. Their feedback fuels our passion and commitment to excellence.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 animation-2-combine">
            <div className="">
              <CarouselContainer>
                <Slider {...settingM}>
                  {allreview.map((val,i)=>(
                  <div className="p-3 animation-2" key={i}>
                    <div className="position-relative">
                    <img
                      className="img-fluid-1-ani"
                      src="/assets/review.png"
                      style={{width:"100%"}}
                      alt="Slide 1"
                    />
                    <div className="review-position">
                    <div className="box-in">
                    <h5>{val.user.name}</h5>
                    <Rate disabled defaultValue={val.rating} />
                    <p className="review-para">{val.review}</p>
                    </div>
                    </div>
                    </div>
                  </div>))}
                </Slider>
              </CarouselContainer>
            </div>
            </div>
          </div>
        </Container>
      </section>

    </>
  );
};

export default Testnimonal;
