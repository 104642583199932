import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const ReturnFunds = () => {
  return (
    <>
      <Helmet>
        <title>Return & Refund Policy</title>
        <meta
          name="description"
          content="Understand the return and refund policies at Sara Stock Designs. Learn about our hassle-free return process and customer satisfaction guarantee. Shop with confidence knowing you can easily return or exchange your embroidery designs."
        />
      </Helmet>
      <section className="py-4" style={{ backgroundColor: "#fff" }}>
        <Container  className="con">
          <div className="position-relative">
            <h2 className="title">Return And Refund policies</h2>
          </div>
          <div className="policy-content">
            <p>At Sara Stock Designs, your satisfaction is our priority. If you are not completely satisfied with your purchase, we’re here to help.</p>
            <h2>Returns</h2>
            <h3>Eligibility:</h3>
            <p>To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.</p>
            <h3>Time Frame:</h3>
            <p>You have 30 calendar days to return an item from the date you received it.</p>
            <h3>Process:</h3>
            <p>To initiate a return, please contact our customer service team with your order number and details of the product you wish to return. We will provide you with instructions on how to send your item back to us.</p>
            <h2>Refunds</h2>
            <h3>Approval:</h3>
            <p>Once we receive your returned item, we will inspect it and notify you that we have received it. We will also inform you of the approval or rejection of your refund.</p>
            <h3>Processing:</h3>
            <p>If your return is approved, we will process your refund. A credit will automatically be applied to your original method of payment within a certain number of days, depending on your card issuer's policies.</p>
            <h2>Exchanges</h2>
            <h3>Defective or Damaged Items:</h3>
            <p>If you received a defective or damaged item, please contact us immediately with the details of the product and the defect. We will arrange for a replacement or a full refund.</p>
            <h2>Shipping</h2>
            <h3>Return Shipping Costs:</h3>
            <p>You will be responsible for paying your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.</p>
            <h3>Shipping Time:</h3>
            <p>Depending on your location, the time it may take for your exchanged product to reach you may vary.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions about our Refund & Returns policy, please contact us at:</p>
            <p><strong>Email:</strong><a href="mailto:support@rgembroiderydesigns.com">support@rgembroiderydesigns.com</a></p>
            <p><strong>Phone:</strong><a href="tel:+1 (123) 456-7890">+1 (123) 456-7890</a></p>
            <p>Thank you for shopping with Sara Stock Designs. We appreciate your business and are committed to ensuring your satisfaction.</p>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ReturnFunds;
