import React, { useEffect } from 'react'
import Footer from '../Footer/Footer'
import Vector from '../Home/Vector'

const VectorTrace = () => {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      },[])
    return (
        <>
            <Vector/>
            <Footer/>
        </>
    )
}

export default VectorTrace
