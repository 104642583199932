import React, { useEffect } from "react";
import Newarrive from "../Home/Newarrive";
import Sellingbanner from "../Home/Sellingbanner";
import Bestselling from "../Home/Bestselling";
import Footer from "../Footer/Footer";
import Banner from "../Home/Banner";
import Faq from "../Home/Faq";
import { Helmet } from "react-helmet";
import Testnimonal from "../Home/Testnimonal";

const Home = () => {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      },[])
  return (
    <>
        <Helmet>
        <title>RG Embroidery Design: Get High-Quality Embroidery Designs at Best Rates</title>
        <meta
          name="description"
          content="Discover a wide range of premium embroidery designs at RG Embroidery Designs. Perfect for all your crafting needs, our collection offers unique and intricate patterns. Shop now for high-quality, customizable embroidery designs that stand out."
        />
      </Helmet>
      <Banner />
      <Newarrive />
      <Sellingbanner />
      <Testnimonal/>
      {/* <Bestselling />
      <Faq/> */}
      <Footer />
    </>
  );
};

export default Home;
