import React, { useEffect, useState } from "react";
import { FaStar, FaRegHeart, FaEye, FaHeart } from "react-icons/fa";
import { AiOutlineShoppingCart, AiFillShopping } from "react-icons/ai";
import { IoIosShareAlt, IoMdCloseCircleOutline } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Modal, Rating, Table } from "@mui/material";
import { Container } from "react-bootstrap";
import { API_URL } from "../Config";
import { ApiEndPoints } from "../Providers/api_endpoints";
import {
  deleteitem,
  forgetPass,
  getMe,
  loginUser,
  patchuser,
} from "../Providers/Apicalls";
import { message, Rate } from "antd";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
}

interface CardProps {
  data: Product[];
}

type UserData = {
  name: string;
  phone: number | null;
  email: string;
  role: string;
  orders: [];
  cart: string[];
  wishlist: string[];
  wallet: number | null;
  _id: string;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Card: React.FC<CardProps> = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [userData, setUserData] = useState<UserData>({
    name: "",
    phone: null,
    email: "",
    role: "",
    orders: [],
    cart: [],
    wishlist: [],
    wallet: null,
    _id: "",
  });

  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px", // Set the font size
        fontWeight: "bold", // Set the font weight
      },
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        fontSize: "16px", // Set the font size
        fontWeight: "bold", // Set the font weight
      },
    });
  };

  const handleOpen = (product: Product) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProduct(null);
  };

  const handleViewProduct = (key: any) => {
    if (key.category === "embroidery design") {
      navigate(`/embroidery-design/${key.slug}`);
    } else if (key.category === "print design") {
      navigate(`/print-design/${key.slug}`);
    } else {
      navigate("/freebies");
    }
  };

  const getUser = async () => {
    try {
      const getMeuser = await getMe(ApiEndPoints("getMe"));

      // Set the user data to state
      setUserData(getMeuser.user);

      // Extract wishlist IDs from the user data
      const userWishlist = getMeuser.user.wishlist.map((item: any) => item);

      // Check if products in the current view are in the wishlist
      data.forEach((product) => {
        const isInWishlist = userWishlist.includes(product._id);
        isProductInWishlist(isInWishlist);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const isProductInWishlist = (productId: string) => {
    return userData.wishlist.some((item) => item === productId);
  };

  const isProductInCart = (productId: string) => {
    return userData.cart.some((item) => item === productId);
  };

  const handleWishlist = async (
    product: Product,
    action: "wishlist" | "cart"
  ) => {
    try {
      const payload = { product: product._id };

      if (action === "wishlist") {
        if (isProductInWishlist(product._id!)) {
          // Remove from wishlist
          await deleteitem(ApiEndPoints("removeFromWishlist"), payload);
        } else {
          // Add to wishlist
          await forgetPass(ApiEndPoints("addtoWishlist"), payload);
        }

        // Update local state
        setUserData((prevState) => {
          const updatedWishlist = isProductInWishlist(product._id!)
            ? prevState.wishlist.filter((id) => id !== product._id)
            : [...prevState.wishlist, product._id!];
          return { ...prevState, wishlist: updatedWishlist };
        });
      } else if (action === "cart") {
        if (isProductInCart(product._id!)) {
          // Remove from cart
          await deleteitem(ApiEndPoints("removeFromCart"), payload);
        } else {
          // Add to cart
          await forgetPass(ApiEndPoints("addtoCart"), payload);
        }

        // Update local state
        setUserData((prevState) => {
          const updatedCart = isProductInCart(product._id!)
            ? prevState.cart.filter((id) => id !== product._id)
            : [...prevState.cart, product._id!];
          return { ...prevState, cart: updatedCart };
        });
      }

      successmessage(
        `Product ${
          action === "wishlist" ? "wishlist" : "cart"
        } updated successfully.`
      );
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  const shareData = {
    title: "RG Designs",
    text: "Buy all types of design at one place",
    url: window.location.href,
  };
  const handleShare = async () => {
    try {
      await navigator.share(shareData);
    } catch (err: any) {
      message.error(err.response.message);
    }
  };

  return (
    <>
      {contextHolder}
      <div className="row mx-0">
        <div className="row mx-0 px-0">
          {data.map((val, index) => (
            <div className="col-lg-3 col-md-6 col-sm-6 col-6 col-five" key={index}>
              <div className="text-center position-relative card-hover py-3">
                <img
                  src={`${API_URL}/public/images/${val.image}`}
                  alt="product"
                  className="img-fluid card-img"
                  onClick={() => handleViewProduct(val)}
                />
                <div className="product-icon">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="pe-3">
                      <div className="heartIcon">
                        {isProductInWishlist(val._id!) ? (
                          <FaHeart
                            onClick={() => handleWishlist(val, "wishlist")}
                            style={{ color: "red", cursor: "pointer" }}
                          />
                        ) : (
                          <FaRegHeart
                            style={{ color: "#6FCFE7", cursor: "pointer" }}
                            onClick={() => handleWishlist(val, "wishlist")}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-details">
                    <h6 className="mb-0 py-1">{val.name}</h6>
                    <p className="mb-0">
                      <span className="card-price">$ {val.price}</span>
                    </p>
                    <span className="d-inline-flex justify-content-center align-items-center">
                      <Rate disabled defaultValue={2} />
                    </span>
                  </div>
                  <div>
                    <div className="carticons">
                      {isProductInCart(val._id!) ? (
                        <AiFillShopping
                          onClick={() => handleWishlist(val, "cart")}
                          style={{ color: "green", cursor: "pointer" }}
                        />
                      ) : (
                        <AiOutlineShoppingCart
                          onClick={() => handleWishlist(val, "cart")}
                          style={{
                            color: "#fff",
                            fontWeight: 700,
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Card;
